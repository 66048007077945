<template>
  <div>
    <HomePageHeroSection />

    <v-container>
      <v-row justify="center">
        <v-col>
          <div class="feedback-stepper-wrapper">
            <ExternalFeedbackStepper flat class="feedback-stepper" />
          </div>
        </v-col>
      </v-row>
      <HowItWorks class="pt-8 mb-15" />
    </v-container>

    <section class="company-section py-15">
      <header class="text-center mb-10">
        <h2 class="title-h2 text-white"
          >Comapnies <br />
          Ready to become user driven?</h2
        >
        <p class="mt-5 font-weight-thin">
          Unlock your full potential with Users Driven
        </p>
      </header>
      <div class="text-center">
        <v-btn
          color="info"
          size="large"
          :append-icon="mdiArrowRight"
          tag="NuxtLink"
          to="/companies"
          >Check our plans</v-btn
        >
      </div>
    </section>

    <v-container>
      <section class="px-6 pt-8 mb-10">
        <header class="text-center mb-15">
          <h2 class="title-h2">Users Driven Boosts your career</h2>
          <p class="mt-5 font-weight-thin">
            Unlock your full potential with Users Driven
          </p>
        </header>
        <div>
          <v-row>
            <v-col
              v-for="feature in features"
              :key="feature.title"
              cols="12"
              sm="6"
              md="4"
              class="mb-6 feature-col draw meet"
            >
              <header>
                <span>
                  <v-icon
                    color="secondary"
                    class="feature-icon"
                    :icon="feature.icon"
                  />
                  <h3 class="d-inline">
                    {{ feature.title }}
                  </h3>
                </span>
              </header>
              <footer>
                <p class="font-weight-thin feature-col__description">
                  {{ feature.description }}
                </p>
              </footer>
            </v-col>
          </v-row>
        </div>
      </section>

      <FAQ :items="homePageFAQItems" />
    </v-container>
  </div>
</template>

<script setup>
import {
  mdiFinance,
  mdiCurrencyUsd,
  mdiCommentQuote,
  mdiAccountGroup,
  mdiTestTube,
  mdiRocketLaunch,
  mdiArrowRight,
} from '@mdi/js';

import HowItWorks from '@/components/HomePage/HowItWorks.vue';
import HomePageHeroSection from '@/components/HomePage/HomePageHeroSection.vue';
import ExternalFeedbackStepper from '@/components/Feedback/external/ExternalFeedbackStepper.vue';
import FAQ from '@/components/FAQ.vue';
import { DEFAULT_DESCRIPTION } from '@/constants/seo';
import { homePageFAQItems } from '../constants/FAQ';

defineOptions({
  name: `HomePage`,
});

useHTMLTagsForSEO({
  fullTitle: 'Users Driven - Community Feedback Platform',
  pagePath: '',
  modifiedDate: '2024-07-06',
  description: DEFAULT_DESCRIPTION,
});

const features = [
  {
    title: `Accelerated Career Growth`,
    description: `Catch potential employers' attention`,
    icon: mdiFinance,
  },
  {
    title: `Profit Sharing`,
    description: `Gain money from your valuable contribution`,
    icon: mdiCurrencyUsd,
  },
  {
    title: `Personalized Feedback`,
    description: `Improve your skills with tailored comments`,
    icon: mdiCommentQuote,
  },
  {
    title: `Professional Network`,
    description: `Connect with like-minded individuals in your field`,
    icon: mdiAccountGroup,
  },
  {
    title: `Test Your Skills`,
    description: `Transform ideas into actionable changes`,
    icon: mdiTestTube,
  },
  {
    title: `Helps Juniors`,
    description: `Accumulate experience in the industry`,
    icon: mdiRocketLaunch,
  },
];
</script>

<style scoped>
.company-section {
  background: rgba(11, 18, 31, 0.95);
  color: white !important;
}
.feedback-stepper-wrapper {
  margin-bottom: 50px;
}
.feedback-stepper {
  padding: 8px;
  background: #ffffff;
  box-shadow: 0px 20px 70px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
}

@media only screen and (max-width: 576px) {
  .feedback-stepper {
    padding: 4px 0px;
  }
}

:deep(.v-sheet.v-stepper.v-stepper--alt-labels, .v-stepper-header) {
  box-shadow: none !important;
}
:deep(.v-stepper-item) {
  padding: 8px;
}

:deep(.v-stepper--alt-labels .v-stepper-item__avatar.v-avatar) {
  margin-bottom: 10px;
}

.feature-col {
  border: 0;
}

.feature-icon,
.feature-icon .v-icon__svg {
  display: inline-block !important;
  margin-inline-end: 5px;
  vertical-align: text-bottom;
}

/* // Basic styles */
.feature-col {
  background: none;
  border: 0;
  box-sizing: border-box;

  isolation: isolate;

  /* // Required, since we're setting absolute on pseudo-elements */
  position: relative;
  vertical-align: middle;

  &::before,
  &::after {
    box-sizing: inherit;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.draw {
  transition: color 0.25s;

  &::before,
  &::after {
    /* // Set border to invisible, so we don't see a 4px border on a 0x0 element before the transition starts */
    border: 2px solid transparent;
    width: 0;
    height: 0;
  }

  /* // This covers the top & right borders (expands right, then down) */
  &::before {
    top: 0;
    left: 0;
  }

  /* // And this the bottom & left borders (expands left, then up) */
  &::after {
    bottom: 0;
    right: 0;
  }

  /* // Hover styles */
  &:hover::before,
  &:hover::after {
    width: 100%;
    height: 100%;
  }

  &:hover::before {
    /* // Make borders visible */
    transition:
      width 0.25s ease-out,
      /* // Width expands first */ /* // And then height */ height 0.25s
        ease-out 0.25s;
  }

  &:hover::after {
    /* // Make borders visible */
    transition:
      border-color 0s ease-out 0.5s,
      /* // Wait for ::before to finish before showing border */ width 0.25s
        ease-out 0.5s,
      /* // And then exanding width */ /* // And finally height */ height 0.25s
        ease-out 0.75s;
  }
}

/* // Inherits from .draw */
.meet {
  &:hover {
    color: #2dd4bf;
  }

  /* // Start ::after in same position as ::before */
  &::after {
    top: 0;
    left: 0;
  }

  /* // Change colors */
  &:hover::before {
    border-top-color: #2dd4bf;
    border-right-color: #2dd4bf;
  }

  &:hover::after {
    border-bottom-color: #2dd4bf;
    border-left-color: #2dd4bf;
    /* // Animate height first, then width */
    transition:
      height 0.25s ease-out,
      width 0.25s ease-out 0.25s;
  }

  &:hover :deep(.feature-icon .v-icon__svg) {
    fill: #2dd4bf;
  }
}

.faq-subtitle {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: rgba(34, 34, 34, 0.75);
}
</style>
