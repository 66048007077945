export const homePageFAQItems = [
  {
    title: `What is Users Driven?`,
    text: `Users Driven is an innovative community based platform that allows users to provide feedback and insights on apps and websites. It helps businesses improve their digital experiences by incorporating user feedback into their development process.`,
  },
  {
    title: `How can I become a contributor in Users Driven?`,
    text: `To join the Users Driven community, simply go to our giving feedback page by <a href='/give-feedback'>clicking here</a>. There you can start providing feedback and earning rewards.`,
  },
  {
    title: `What are the benefits of joining Users Driven?`,
    text: `By joining Users Driven, you get the opportunity to contribute to the improvement of digital experiences, gain recognition for your insights, and even earn rewards for your feedback. It's a win-win situation for both users and businesses. Learn more in the How it works section.`,
  },
  {
    title: `Can I provide feedback anonymously?`,
    text: `Yes, Users Driven allows users to provide feedback anonymously if they prefer. We respect your privacy and understand that some users may feel more comfortable sharing their thoughts without revealing their identity. If you want to stay anonymous, when submitting your feedback check the anonymous checkbox.`,
  },
  {
    title: `How do I earn money on Users Driven?`,
    text: `Users Driven uses profit sharing in order to share our income with you, it depends on specific website or app that you provide the feedback to, and their willingness to pay for it. But don't worry we'll do our best to get you paid properly for your time. There are several parameters we take into account: the amount of money you think you need to get paid, the severity of the issue reported, the reported company's revenues, the historical data we have, and the amount of money your feedback helps them save or earn. When submitting your feedback you can state how much you think your feedback is worth, obviously we can't promise that amount but we'll do our best to get it. Check our <a href='/terms-of-service'>Terms of service</a> to learn more.`,
  },
];

export const companiesPageFAQItems = [
  {
    title: `How will you help my company?`,
    text: `We will help you create User Driven experience, which will decrease churn, improve user satisfaction, and will allow you to increase your revenue by:
    1. Report issues in your app (design, performance or bugs)
    2. 
    By analyzing each part of your digital product (QA, performance, design, product) we're able to understand better your current situation and how to improve it
    `,
  },
  {
    title: `Introduction conversation`,
    text: `TODO: ADD info`,
  },
];
