<template>
  <section>
    <h3 id="how-it-works" class="title-h2 text-center mb-12"
      >How Users Driven works</h3
    >
    <v-timeline
      align="start"
      :side="showTimelineOnOneSide ? 'end' : undefined"
      truncate-line="both"
    >
      <v-timeline-item
        v-for="(item, i) in computedItems"
        :key="i"
        :hide-opposite="!item.title"
        :hide-dot="!showTimelineOnOneSide"
        :dot-color="item.color"
        :icon="item.icon"
        :fill-dot="!item.title"
        icon-color="white"
      >
        <v-card
          v-if="item.title"
          class="timeline-item-card"
          color="white"
          elevation="10"
          :class="{
            // Add margin just on negative items when showing timeline on both sides
            'mt-6': !showTimelineOnOneSide && i > 1 && i % 2 === 1,
            'timeline-item-card-max-width': showTimelineOnOneSide,
          }"
          :style="{
            // Add negative margin just on even items when showing timeline on both sides
            'margin-top': !showTimelineOnOneSide && i % 2 === 0 ? '-70px' : '',
          }"
        >
          <v-card-title :class="['text-h6', 'card-title']" tag="h3">
            <p class="font-weight-bold card-title-content d-inline">
              {{ item.title }}
            </p>
          </v-card-title>
          <v-card-text class="card-content text--primary pt-0">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p v-html="item.content"></p>
            <div
              v-if="i === computedItems.length - 1"
              class="text-center mt-5 mb-2"
            >
              <v-btn
                color="info"
                tag="a"
                to="/give-feedback"
                :append-icon="mdiArrowRight"
                size="large"
                class="hero-action-btn"
              >
                Start now
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-timeline-item>
    </v-timeline>
  </section>
</template>

<script setup>
import {
  mdiLightbulbOn,
  mdiFileCheck,
  mdiHandshake,
  mdiCurrencyUsd,
  mdiMedal,
  mdiRunFast,
  mdiArrowRight,
} from '@mdi/js';

defineOptions({
  name: `HowItWorks`,
});

onMounted(() => {
  try {
    const timelineItemsOppositeElements = document.querySelectorAll(
      `.v-timeline-item__opposite`,
    );
    const timelineItemsOppositeArray = [...timelineItemsOppositeElements];
    timelineItemsOppositeArray.forEach((element) => element.remove());
  } catch {
    console.warn(`Couldn't remove redundant timeline elements`);
  }
});

onUnmounted(() => {
  window.removeEventListener(`resize`, checkEnoughSpaceInViewport, {
    passive: true,
  });
});

const showTimelineOnOneSide = ref(true);

const checkEnoughSpaceInViewport = () => {
  if (window.visualViewport.width < 850) {
    showTimelineOnOneSide.value = true;
  } else {
    showTimelineOnOneSide.value = false;
  }
};

const items = [
  {
    title: `1. Find a bug or an idea`,
    content: `Find an issue or think about a possible improvement to one of the websites/apps that you use.`,
    color: `primary`,
    icon: mdiLightbulbOn,
  },
  {
    title: `2. Report it to Users Driven`,
    content: `Send Users Driven your feedback (issue/idea) through <a href="/give-feedback">our Feedback page</a>`,
    color: `secondary`,
    icon: mdiFileCheck,
  },
  {
    title: `3. Let us take care of it`,
    content: `1. We'll review your feedback.
      <br> 2. In case it is good we'll contact the website/app owner and ask them to <b>pay</b> for that.`,
    color: `primary`,
    icon: mdiHandshake,
  },
  {
    title: `4. Get money & feedback`,
    content: `We'll <b>share with you the profits</b> received from the website/app owner and their feedback about your idea/issue. Check our <a href="/terms-of-service">terms of service</a> for more information.`,
    color: `secondary`,
    icon: mdiCurrencyUsd,
  },
  {
    title: `5. Win prizes and boost your career`,
    content: `1. Earn tokens and win prizes. <br> 2. Boost your career using feedback portfolio, improved CV and exposure in our website.`,
    //  Climb the scoreboard according to the feedback you gave. <br> 2. The feedback you provided will appear on your user page to help you with your career. <br> 3. We'll share your profile (if you wish) with the company that received the details
    color: `primary`,
    icon: mdiMedal,
  },
  // {
  //   title: `6. Provide rank for the website/app`,
  //   content: `We'll calculate it according to several parameters such as: speed of response, payment, adoption of feedback, we'll provide a score to each website/app, so that users could spend their time wisely.`,
  //   color: `secondary`,
  //   icon: `mdi-calculator`,
  // },
  {
    title: `Let's start!`,
    content: `It's time to provide your first feedback. We really want to hear about it`,
    color: `secondary`,
    icon: mdiRunFast,
  },
];

const computedItems = computed(() => {
  if (showTimelineOnOneSide.value) {
    return items;
  }
  // Inserting an empty item to switch the sides of the cards
  return [
    {
      title: ``,
      content: ``,
      color: ``,
      icon: ``,
    },
    ...items,
  ];
});
</script>

<style scoped>
.timeline-item-card {
}

.timeline-item-card-max-width {
  max-width: 400px;
}

@media only screen and (max-width: 450px) {
  .timeline-item-card {
    max-width: 270px;
  }
  .card-title-content {
    font-size: 16px;
  }

  .v-timeline--vertical.v-timeline--justify-auto {
    grid-template-columns: min-content;
  }

  .v-timeline--vertical.v-timeline.v-timeline--side-end
    .v-timeline-item
    .v-timeline-item__body {
    padding-inline-start: 0px;
  }

  .card-title.v-card-title {
    overflow: unset;
    text-overflow: unset;
    white-space: unset;
    line-height: 1rem;
  }
}

.card-title {
}

.card-title-content {
}

.card-content {
  font-family: 'Inter';
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.6);
}

.v-card.timeline-item-card {
  background:
    linear-gradient(white, white) padding-box,
    linear-gradient(to right, #7f56d9, #2ed4bf) border-box;
  border-radius: 8px;
  border: 5px solid transparent;
}

.v-timeline--vertical.v-timeline {
  row-gap: 24px;
}
</style>

<style>
.v-timeline--vertical.v-timeline
  .v-timeline-item:first-child
  .v-timeline-divider,
.v-timeline--vertical.v-timeline
  .v-timeline-item:first-child
  .v-timeline-item__body,
.v-timeline--vertical.v-timeline
  .v-timeline-item:first-child
  .v-timeline-item__opposite {
  padding-block-start: 0 !important;
}
</style>
